import React from "react"
import SEO from "../components/seo"
import { Header1, Button } from "../elements"
import { Col, Row } from "react-grid-system"
import TransitionContext from "../context/TransitionContext"

const Success = props => (
  <>
    <SEO title="Form Submitted" pathname={props.location.pathname} />
    <TransitionContext location={props.location}>
      <Col align="center" justify="center" className="pagemargintop">
        <Header1 lineOne="Got it!" lineTwo="We've received your submission." />
        <Row>
          <Col>
            <p>A member of the PAKD crew will be in touch ASAP.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button secondary linkTo="/">
              Back to Homepage
            </Button>
          </Col>
        </Row>
      </Col>
    </TransitionContext>
  </>
)

export default Success
